<script>
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import { required } from "vuelidate/lib/validators";
import ContractorDetailModal from "./modals/contractorDetail";
import DeletionModal from "@/components/widgets/deletion-modal.vue";
import Repositories from "@/repositories";
const ContractorsRepository = Repositories.get("contractors");

/**
 * Dashboard Component
 */
export default {
  page: {
    title: "contractors",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    PageHeader,
    ContractorDetailModal,
    DeletionModal,
  },
  data() {
    return {
      title: "Contractors",
      isLoading: false,
      contractors: [],
      contractorSubmit: false,
      contractorForm: {
        name: null,
      },
    };
  },
  created() {
    //Load progress
    this.isLoading = true;
    ContractorsRepository.get({ name: null })
      .then((response) => {
        this.contractors = response.data;
      })
      .finally(() => {
        this.isLoading = false;
      });
  },
  mounted() {},
  methods: {
    showDeletionModal(id, index) {
      this.$refs.contractorDeleteModal.show().then(() => {
        this.isLoading = true;
        ContractorsRepository.delete(id)
          .then(() => {
            this.contractors.splice(index, 1);
          })
          .finally(() => {
            this.isLoading = false;
          });
      });
    },
    submit() {
      this.contractorSubmit = true;
      this.$v.contractorForm.$touch();

      if (!this.$v.$invalid) {
        this.isLoading = true;
        ContractorsRepository.create({ name: this.contractorForm.name })
          .then((response) => {
            let contractor = response.data;
            this.contractors.push({ ...contractor });

            this.contractorForm.name = null;
          })
          .finally(() => {
            this.isLoading = false;
            this.contractorSubmit = false;
          });
      }
    },
    edit(contractor) {
      this.$refs.contractorDetail
        .showEdit({ ...contractor })
        .then((newContractor) => {
          contractor.name = newContractor.name;
        });
    },
  },
  validations: {
    contractorForm: {
      name: { required },
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="$t('general.contractors')" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <p class="card-title-desc mb-4">
                  {{ $t("general.contractorsList") }}
                </p>
                <form
                  @submit.prevent="submit"
                  style="display: flex; align-items: flex-end"
                >
                  <div class="form-group" style="position: relative">
                    <b-form-input
                      :class="{
                        'is-invalid':
                          contractorSubmit && $v.contractorForm.name.$error,
                      }"
                      style="max-width: 300px"
                      v-model="contractorForm.name"
                      :placeholder="$t('general.name')"
                    >
                    </b-form-input>
                    <div
                      v-if="contractorSubmit && $v.contractorForm.name.$error"
                      class="invalid-tooltip"
                    >
                      <span v-if="!$v.contractorForm.name.required">{{
                        $t("general.requiredValue")
                      }}</span>
                    </div>
                  </div>
                  <b-form-group>
                    <b-button type="submit" class="ml-3" variant="primary">{{
                      $t("general.add")
                    }}</b-button>
                  </b-form-group>
                </form>
              </div>
            </div>

            <div ref="progressTable" class="table-responsive">
              <loading
                :active.sync="isLoading"
                :can-cancel="false"
                color="#f1b44c"
                :width="70"
                :height="70"
                loader="dots"
                :is-full-page="false"
              >
              </loading>
              <table class="table mb-0 table-hover">
                <thead class="thead-light">
                  <tr>
                    <th>{{ $t("general.name") }}</th>
                    <th style="width: 100px; text-align: center">
                      {{ $t("general.action") }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(contractor, index) in contractors" :key="index">
                    <td>{{ contractor.name }}</td>
                    <td style="text-align: center">
                      <b-dropdown
                        dropleft
                        size="lg"
                        class="card-drop"
                        variant="link"
                        toggle-class="text-decoration-none p-0"
                        no-caret
                      >
                        <template #button-content>
                          <i class="fas fa-ellipsis-h" />
                        </template>

                        <b-dropdown-item @click="edit(contractor)">
                          <i class="fas fa-pencil-alt text-success mr-1"></i>
                          {{ $t("general.edit") }}
                        </b-dropdown-item>

                        <b-dropdown-item
                          @click="showDeletionModal(contractor.id, index)"
                        >
                          <i class="fas fa-trash-alt text-danger mr-1"></i>
                          {{ $t("general.delete") }}
                        </b-dropdown-item>
                      </b-dropdown>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <DeletionModal
      ref="contractorDeleteModal"
      :content="this.$t('general.deleteContractorContent')"
      :title="this.$t('general.deleteContractorTitle')"
    />
    <ContractorDetailModal ref="contractorDetail" />
  </Layout>
</template>
