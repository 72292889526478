
<script>
import { required } from "vuelidate/lib/validators";
import Repositories from "@/repositories";
const ContractorsRepository = Repositories.get("contractors");

export default {
  components: {},
  data() {
    return {
      showModal: false,
      title: "Edit contractor",
      contractorForm: {
        name: null,
        id: null,
      },
      isContractorFormSubmit: false,
      resolvePromise: null,
      rejectPromise: null,
    };
  },
  created() {},
  beforeDestroy() {},
  computed: {},
  methods: {
    showEdit: function (contractor) {
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
        this.contractorForm = contractor;
        this.showModal = true;
      });
    },
    closeModal() {
      this.showModal = false;
      this.isContractorFormSubmit = false;
      this.contractorForm = {
        id: null,
        name: null,
      };
    },
    contractorFormSubmit() {
      this.isContractorFormSubmit = true;
      this.$v.contractorForm.$touch();

      if (!this.$v.contractorForm.$invalid) {
        //save form
        ContractorsRepository.update(
          this.contractorForm,
          this.contractorForm.id
        ).then((response) => {
          this.resolvePromise(response.data);
          this.closeModal();
        });
      }
    },
  },
  validations: {
    contractorForm: {
      name: { required },
    },
  },
};
</script>

<template>
  <b-modal
    v-model="showModal"
    :title="$t('general.editContractor')"
    title-class="text-black font-18"
    hide-footer
    body-class="p-3"
    size="sm"
  >
    <b-form @submit.prevent="contractorFormSubmit">
      <div class="row">
        <b-form-group style="position: relative" class="col-12">
          <label>{{ $t("general.name") }}</label>
          <b-form-input
            v-model="contractorForm.name"
            :class="{
              'is-invalid':
                isContractorFormSubmit && $v.contractorForm.name.$error,
            }"
            :placeholder="$t('general.name')"
          >
          </b-form-input>
          <div
            v-if="isContractorFormSubmit && $v.contractorForm.name.$error"
            class="invalid-feedback"
          >
            <span v-if="!$v.contractorForm.name.required">{{
              $t("general.valueRequired")
            }}</span>
          </div>
        </b-form-group>
      </div>
      <div class="text-right pt-3">
        <b-button variant="light" @click="closeModal">{{
          $t("general.close")
        }}</b-button>
        <b-button type="submit" class="ml-1" variant="success">{{
          $t("general.save")
        }}</b-button>
      </div>
    </b-form>
  </b-modal>
</template>